:import('./styles/colors.css'){
  i__const_c_black_0: c_black;
  i__const_c_red_1: c_red;
  i__const_c_grey_mid_2: c_grey_mid;
}

:export{
  c_black: i__const_c_black_0;
  c_red: i__const_c_red_1;
  c_grey_mid: i__const_c_grey_mid_2;
}

@import './styles/colors-v1.css';

/*** General QOL ***/
*, *::after, *::before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-kerning: auto;
          font-kerning: auto;
}

/* region font */
body, input, select, textarea, button {
  font-family: 'Nunito', sans-serif;
}

label {
  font-family: 'Nunito', sans-serif;
  letter-spacing: normal;
}


h1, h2, h3, h4, h5, h6 {
  font-family: 'Nunito', sans-serif;
}

.h-gradient {
  font-family: 'Libre Baskerville', Verdana, sans-serif;
}

code, sup {
  font-family: 'Overpass Mono', Verdana, monospace;
}

/* endregion font */


/* Reset focus outline */
:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

* {
  -webkit-tap-highlight-color: transparent;
  word-break: break-word;
  scrollbar-color: #D7E1EB #F6F9FC;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}

html, body, #root {
  height: 100%;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6;
  color: #000000;
}

html{
  border: none !important;
}

strong {
  font-weight: 600;
}

blockquote {
  position: relative;
  background: rgba(0, 0, 0, 0.05);
  margin: 1rem 0 1.5rem;
  padding: 0.8rem;
  border-left: 4px solid #EDE1DF;
}

blockquote p:last-child {
  margin-bottom: 0;
}

a {
  position: relative;
  text-decoration: underline;
  font-weight: 600;
  color: i__const_c_grey_mid_2;
  cursor: pointer;
}

h1, h2, h3, h4, h5 {
  color: i__const_c_black_0;
  margin-top: 0;
  font-weight: 600;
  line-height: 1;
}

h5 {
  font-size: 1.137rem;
  margin-bottom: 0.8rem;
  font-weight: 600;
  letter-spacing: 0;
  padding-bottom: 0.55rem;
  border-bottom: none;
}

h1 {
  font-size: 3.4rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 1.9rem;
  margin-bottom: 1rem;
}

h4 {
  font-size: 1.27rem;
}

h6 {
  color: i__const_c_black_0;
  font-size: 1.1rem;
  letter-spacing: 1px;
  font-weight: 600;
}

.text-red {
  color: i__const_c_red_1;
}

.text-light {
  color: rgba(0, 0, 0, 0.35);
}

.text-bold {
  font-weight: 600;
}

hr {
  height: 1px;
  background: rgba(0, 0, 0, 0.09);
  border: none;
  margin-top: 1.76rem;
  margin-bottom: 1.76rem;
}

b {
  font-weight: 600;
}

p {
  margin-top: 0;
  margin-bottom: 1.3rem;
}

p:last-child {
  margin-bottom: 0;
}

i {
  color: #636363;
}

:global(.modal) {
  height: 100% !important;
  overflow-y: hidden !important;
  padding: 0.5rem !important;
}

:global(.modal-dialog) {
  height: 100% !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  pointer-events: none;
}

:global(.modal-content) {
  pointer-events: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  max-height: 95% !important;
}

:global(.modal-body) {
  overflow-y: auto;
  -ms-flex-negative: 1;
      flex-shrink: 1;
}

:global(.modal-backdrop.in) {
  z-index: 1050;
}

.DayPickerKeyboardShortcuts_closeSvg {
  fill: #8E9195;
}

.DayPickerKeyboardShortcuts_show__bottomRight:focus::before {
  border-right: 33px solid #008489;
}

.DayPickerNavigation_svg__horizontal {
  fill: #8B9292;
}

*:focus {
  outline: 1px dotted #1C1C1C !important;
}

.innerInputFocusNone input:focus {
  outline: 0 !important;
}

.innerInputFocusNone:focus-within {
  outline: 1px dotted #1C1C1C !important;
}

.ReactModal__Content :global(.modal-body) {
  max-height: calc(100vh - 160px);
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

@media (min-width: 768px) {
  .modal-sm {
    width: auto;
    max-width: 320px;
  }
}

.visibilityHidden {
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
          clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

@media only screen and (max-width: 767px) {
  .ReactModal__Content :global(.modal-body) {
    max-height: calc(100vh - 130px);
  }

  .ReactModal__Content {
    margin: 0 auto !important;
  }
}

@media only screen and (max-width: 567px) {
  .DateRangePickerInput, .DateInput, .DateRangePicker {
    width: 100%;
  }

  .DateRangePickerInput_arrow span {
    padding-left: 0;
  }

  :global(.modal-header) {
    padding: 5px 15px;
  }

  :global(.modal-title) {
    font-size: 1rem;
  }

  :global(.modal-content) {
    max-height: 100% !important;
  }

  :global(.modal) {
    padding: 0.2rem !important;
  }

  :global(.modal-dialog) {
    margin: 0;
  }
}

/* Class for bringing scroll in page to prevent cookie panel freeze */
@media all and (max-height: 440px) {
  .scrollHeight {
    margin-bottom: 150px;
    min-height: calc(100vh + 150px);
  }
}

.focusBg:focus-within {
  background: #c5b7ff;
}

.DateRangePickerInput_calendarIcon {
  margin: 0 5px 0 0;
  padding-left: 0;
}


/* region scrollbar css */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track-piece {
  background-color: #F6F9FC;
}

::-webkit-scrollbar-corner {
  background-color: #F6F9FC;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #D7E1EB;
  border-radius: 12px;
  -webkit-box-shadow: inset 0 0 6px rgba(201, 201, 201, 0.1);
}

::-webkit-scrollbar-thumb:hover {
  background: #2741AE;
}

/* endregion scrollbar css */
