.rbc-month-header {
  height: 25px;
  position: sticky;
  top: 0;
  background: #fff;
  border-top: 1px rgb(221, 221, 221) solid;
  z-index: 7;
}

.rbc-month-view {
  border-top: none;
}

.rbc-header {
  line-height: 2em !important;
  font-size: 0.8em !important;
  text-transform: uppercase;
}

.rbc-day-slot .rbc-events-container {
  right: 0 !important;
}

.rbc-event {
  background-color: inherit !important;
  padding: 0 !important;
  border: none !important;
  border-radius: 0;
}

.rbc-event-label {
  display: none !important;
}

.rbc-event-content {
  line-height: 0.1;
}

.rbc-day-bg.day-passed {
  cursor: not-allowed;
}

.rbc-day-bg:not(.day-passed) {
  cursor: cell;
}

.rbc-current-time-indicator {
  background-color: #6E4AFF;
}

.rbc-current-time-indicator:before {
  display: block;
  position: absolute;
  left: -3px;
  top: -3px;
  content: ' ';
  background-color: #6E4AFF;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.rbc-header a {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.rbc-allday-cell {
  height: 30px;
}
