
input, select, textarea,
input.form-control,
select.form-control,
textarea.form-control {
  border: 1px solid #696969;
  -webkit-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
  background: #fff;
}

textarea {
  max-width: 100%;
}

input:not([type=submit]), select,
input:not([type=submit]).form-control, select.form-control {
  height: 2.45rem;
  border-radius: 8px;
}

input,
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
textarea,
select,
option,
input.form-control,
input[type='email'].form-control,
input[type='number'].form-control,
input[type='password'].form-control,
input[type='search'].form-control,
input[type='tel'].form-control,
input[type='text'].form-control,
input[type='url'].form-control,
textarea.form-control,
select.form-control,
option.form-control {
  width: 100%;
  font-size: 1rem;
  font-family: inherit;
  color: inherit;
  padding: 0 0.59rem;
}

input.form-control:not([disabled]):hover,
input.form-control:not([disabled]):focus,
select.form-control:not([disabled]):hover,
select.form-control:not([disabled]):focus,
textarea.form-control:not([disabled]):hover,
textarea.form-control:not([disabled]):focus {
  border: 1px solid #888;
  background: #fff;
  outline: none;
}

input.form-control:not([disabled]):hover,
select.form-control:not([disabled]):hover,
textarea.form-control:not([disabled]):hover {
  -webkit-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
}

input.form-control:not([disabled]):focus,
select.form-control:not([disabled]):focus,
textarea.form-control:not([disabled]):focus {
  -webkit-box-shadow: 0 1px 5px rgba(10, 0, 30, 0.15);
          box-shadow: 0 1px 5px rgba(10, 0, 30, 0.15);
}


input.req, select.req, textarea.req {
  border-color: #ffbd79;
  background: #fbf2e5;
}

input.req:not([disabled]):hover,
input.req:not([disabled]):focus,
select.req:not([disabled]),
select.req:not([disabled]):hover,
select.req:not([disabled]):focus,
textarea.req:not([disabled]),
textarea.req:not([disabled]):hover,
textarea.req:not([disabled]):focus {
  border-color: #c48442;
}

.DateRangePickerInput, .DateInput {
  background-color: transparent;
}

.DateInput_input {
  border: 1px solid #696969;
  -webkit-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 2.45rem;
  border-radius: 8px;
  background-color: #fff;
  font-weight: 600;
}

.DateInput_input__focused {
  border: 1px solid #888;
  background: inherit;
}

input::-webkit-input-placeholder {
  color: #707070;
  opacity: 1;
}

input::-moz-placeholder {
  color: #707070;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: #707070;
  opacity: 1;
}

input::-ms-input-placeholder {
  color: #707070;
  opacity: 1;
}

input::placeholder {
  color: #707070;
  opacity: 1;
}

.css-10clcip-control {
  border-color: #696969 !important;
}

.DateRangePickerCobalt {
  color: #2741AE;
}

.DateRangePickerCobalt .DateRangePickerCobalt__Pill {
  width: 154px;
  height: 28px;
  background: #F4F6FB;
  color: #BBC3E2;
  border-radius: 14px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  padding-left: 10px;
  margin-right: 20px;
}

.DateRangePickerCobalt .DateRangePickerCobalt__PillButton {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background: #E0E4F2;
  color: #BBC3E2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
}

.DateRangePickerCobalt .DateRangePickerInput_calendarIcon {
  display: none;
}

.DateRangePickerCobalt .DateInput_input {
  height: 28px;
  border: 1px solid var(--color-cobalt);
  background: white;
  font-size: 12px;
  color: #2741AE;
  -webkit-box-shadow: none;
          box-shadow: none;
}


.DateRangePickerCobalt .DateInput {
  position: relative;
}

.DateRangePickerCobalt .DateRangePickerInput_arrow {
  color: #2741AE;
}

.emailFilterColDateRange .DateRangePickerCobalt .DateInput {
  width: 78px;
}

.emailFilterColDateRange .DateRangePickerCobalt span{
  white-space: nowrap;
}

.emailFilterColDateRange .DateRangePicker_picker{
  top: calc(100% + 10px) !important;
}

.emailFilterColDateRange .DateInput_fang {
  top: 100% !important;
}

.emailFilterColDateRange .DateRangePickerCobalt .DateInput .DateInput_input{
  height: 26px;
}

.emailFilterColDateRange .DateRangePickerCobalt .DateRangePickerInput_arrow span{
  padding: 0 4px;
}

.emailFilterColDateRange .DateRangePickerCobalt .DateInput input[type='text']{
  padding: 0 0.1rem;
}

select.form-control{
  -webkit-appearance: auto;
     -moz-appearance: auto;
          appearance: auto;
}

@media only screen and (max-width: 1290px){
  .emailFilterColDateRange .DateRangePickerCobalt .DateInput{
      width: 70px;
  }
}

@media only screen and (max-width: 1190px) and (min-width: 992px){
  .emailFilterColDateRange .DateRangePickerCobalt .DateInput input[type='text']{
      font-size: 10px;
  }
  
  .emailFilterColDateRange .DateRangePickerCobalt span,
  .emailFilterColDateRange .DateRangePickerCobalt .DateRangePickerInput_arrow span{
      font-size: 12px;
  }

  .emailFilterColDateRange .DateRangePickerCobalt .DateInput{
      width: 58px;
  }

  .emailFilterColDateRange .DateRangePickerCobalt .DateRangePickerInput_arrow span{
      padding: 0 2px;
  }
}

@media only screen and (max-width: 567px) {
  .DateRangePickerInput, .DateInput, .DateRangePicker {
    width: 100%;
  }

  .DateRangePickerCobalt .DateRangePickerCobalt__Pill {
    margin-bottom: 6px;
    margin-right: 0;
    width: 100%;
  }

  .DateRangePickerCobalt .DateRangePickerInput_arrow {
    display: block;
  }

  .emailFilterColDateRange .DateRangePickerCobalt .DateRangePickerInput_arrow{
      display: inline-block;
  }

  .emailFilterColDateRange .DateRangePickerCobalt span:first-child, 
  .emailFilterColDateRange .DateRangePickerCobalt .DateRangePicker {
    width: auto;
  }
}