.card-content {
    padding: 2rem;
    -webkit-box-shadow: 0 10px 25px rgba(20,0,60,0.12);
            box-shadow: 0 10px 25px rgba(20,0,60,0.12);
    border-radius: 7px;
    margin-bottom: 2.5rem;
    background: #fff;
}

.card-content .icon {
    margin-right: 1rem;
    color: #bbb;
    font-size: 1.18rem;
}

/*Card bars*/

.card-bar {
    background: #fff;
    position: relative;
    cursor: pointer;
    border: 1px solid #dadada;
    -webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.1);
            box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    overflow: hidden;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
}

.card-bar:hover {
    z-index: 5;
    border-color: #ccc;
}

.card-bar.med {
    border-radius: 4px;
    margin-bottom: 1rem;
    padding: 1.47rem;
}

.card-bar.small {
    border-radius: 0;
    border-bottom: none;
    padding: 1.18rem;
    -webkit-transition: all 0.05s;
    -o-transition: all 0.05s;
    transition: all 0.05s;
}

.card-bar.small:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.card-bar.small:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom: 1px solid #ddd;
}

.card-bar.med:hover {
    -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.25);
            box-shadow: 0 2px 8px rgba(0,0,0,0.25);
}

.card-bar.small:hover {
    -webkit-box-shadow: 0 1px 5px rgba(0,0,0,0.25);
            box-shadow: 0 1px 5px rgba(0,0,0,0.25);
    margin-right: -0.22rem;
    margin-left: -0.22rem;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    border-radius: 5px;
}

.card-bar.ko:hover {
    -webkit-box-shadow: none;
            box-shadow: none;
    background: rgba(0,0,0,0.05);
}

.card-bar.ko {
    text-decoration: none;
    border: 2px solid rgba(0,0,0,0.07);
    -webkit-box-shadow: none;
            box-shadow: none;
    background: transparent;
}

/*Card bar contents*/

.card-bar a:hover, .card-bar a:focus {
    background: rgba(0,0,0,0.1);
    border-radius: 4px;
}

.card-bar h4 {
    font-weight: 500;
    color: #555;
}

.card-bar .thumbnail {
    -ms-flex: 0 0 100px;
        flex: 0 0 100px;
    overflow: hidden;
    position: relative;
    margin: 1rem;
    -ms-flex-item-align: stretch;
        align-self: stretch;
}

.card-bar .thumbnail img {
    width: 100%;
    height: auto;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%;
    position: absolute;
}

.card-bar .arrow {
    line-height: 1;
    margin-left: 1.4rem;
    color: rgba(0, 0, 0, 0.18);
}

.card-bar.med .arrow {
    padding: 0 0.6rem;
    font-size: 2.1rem;
    -webkit-transition: 0.1s;
    -o-transition: 0.1s;
    transition: 0.1s;
}

.card-bar.small .arrow {
    -webkit-transition: 0.05s;
    -o-transition: 0.05s;
    transition: 0.05s;
    padding: 0;
    font-size: 1.4rem;
}

/*Turn off box shadow on buttons since card barsr have drop-shadows*/
.card-bar .btn {
    -webkit-box-shadow: none;
            box-shadow: none;
}

/* Re-add hover effect */
.card-bar .btn:hover {
    -webkit-box-shadow: 0 2px 3px rgba(40,20,70,0.3);
            box-shadow: 0 2px 3px rgba(40,20,70,0.3);
}

/*Knockout contents*/

.card-bar.ko .tag {
    border-color: #aaa;
}

.card-bar.ko a {
    color: #777;
}

.card-bar .location.icon {
    color: #4A6173;
    font-size: 1.2rem;
}

.card-bar .icon.learners {
    color: #bbb;
    font-size: 2rem;
}

.card-bar.med:hover .arrow {
    -webkit-transform: translateX(0.5rem);
        -ms-transform: translateX(0.5rem);
            transform: translateX(0.5rem);
}

.card-bar.ko, .card-bar.ko h4 {
    color: #999;
}

.card-bar.ko .icon {
    color: rgba(0,0,0,0.2);
}

.card-bar.ko .btn {
    background: transparent;
    border: 1px solid rgba(0,0,0,0.12);
}

.card-bar.ko .btn:hover {
    border-color: #888;
}

.card-bar.ko .tag:hover {
    background: rgba(0,0,0,0.1);
    border: none;
}
