.SingleDatePicker_picker, .DateRangePicker_picker {
  z-index: 1100;
}

.CalendarDay__selected_span {
  background: #F1EEFE;
  color: #323232;
  border: 1px solid #e4e7e7;
  cursor: pointer;
}

.CalendarDay__selected_span:hover {
  background: #874CD2;
  color: white;
  border: 1px solid #e4e7e7;
  cursor: pointer;
}

.CalendarDay__selected {
  background: #5400bf;
  border: 1px solid #e4e7e7;
  color: white;
  cursor: pointer;
}

.CalendarDay__selected:hover {
  background: #874CD2;
  color: white;
  border: 1px solid #e4e7e7;
  cursor: pointer;
}

.CalendarDay__default:hover, .CalendarDay__default {
  cursor: pointer;
}

.CalendarDay__blocked_out_of_range:hover, .CalendarDay__blocked_out_of_range {
  cursor: default;
}

.CalendarDay { cursor: default; }

.CalendarDay___defaultCursor {
  cursor: pointer;
}
