/* Table */
table {
    width: 100%;
    text-align: left;
    border-spacing: 0;
}

.table>thead>tr>th {
    border: 0;
}

table a:hover, table a:focus {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

th, td {
    border-bottom: 1px solid #f2f2f2;
    padding: 0.3rem .5rem;
    line-height: 1.45;
}

tr:last-child td {
    border-bottom: none;
}

th {
    font-weight: 600;
    font-size: 1.15rem;
}

td:first-child, th:first-child {
    padding-left: 0;
}

td:last-child, th:last-child {
    padding-right: 0;
}

label {
    font-weight: 500;
    cursor: default;
    font-size: 1.27rem;
    margin: 0;
    display: block;
    line-height: 1;
    margin-bottom: 1rem;
}

::-webkit-input-placeholder {
    font-style: italic;
    opacity: 0.6;
}

::-moz-placeholder {
    font-style: italic;
    opacity: 0.6;
}

:-ms-input-placeholder {
    font-style: italic;
    opacity: 0.6;
}

::-ms-input-placeholder {
    font-style: italic;
    opacity: 0.6;
}

::placeholder {
    font-style: italic;
    opacity: 0.6;
}

select option:disabled {
    color: rgba(0, 0, 0, 0.5);
}

::-moz-selection {
    background: #FFE8BA;
    color: #444;
}

::selection {
    background: #FFE8BA;
    color: #444;
}

table ul {
    margin-bottom: 0;
    margin-top: 0;
}
