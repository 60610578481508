:import('./colors.css') {
  i__const_c_purple_light_7: c_purple_light;
}

:export {
  c_purple_light: i__const_c_purple_light_7;
}

.subnav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  font-weight: 500;
  text-decoration: none;
  color: #8c8c8c;
  -ms-flex-align: end;
      align-items: flex-end;
  text-align: center;
  position: relative;
  font-size: 1.1rem;
  background-color: transparent;
  border: none;
  margin-bottom: 0;
  width: 100%;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  padding: 0 0.59rem;
}

.subnav a, .subnav a:hover, .subnav a:focus {
  text-decoration: none;
  font-weight: 500;
}

.subnav.small {
  border-bottom-width: 3px;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.subnav.large {
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.subnav.large li {
  -ms-flex: 1 1 10px;
      flex: 1 1 10px;
  overflow: hidden;
  max-width: 300px;
  text-transform: uppercase;
}

.subnav li {
  text-decoration: none;
  color: #8c8c8c;
  -ms-flex-align: center;
      align-items: center;
  text-align: center;
  position: relative;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0;
  display: inline-block;
}

.subnav.small li {
  -ms-flex-positive: 0;
      flex-grow: 0;
}

.subnav li:hover {
  color: #655c57;
  -webkit-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.2);
}

.subnav.small li:hover {
  -webkit-box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.2);
}

.subnav.nav-tabs > li > a,
.subnav.nav-tabs > li > a:focus,
.subnav.nav-tabs > li > a:hover,
.subnav.nav-tabs > li.active > a,
.subnav.nav-tabs > li.active > a:focus,
.subnav.nav-tabs > li.active > a:hover {
  border: 0;
  background-color: transparent;
  padding: 8px;
}

.subnav > li.active {
  -webkit-box-shadow: 0 4px 0 0 rgba(110, 74, 255, 0.6);
          box-shadow: 0 4px 0 0 rgba(110, 74, 255, 0.6);
}

.subnav.small > li.active {
  -webkit-box-shadow: 0 3px 0 0 rgba(110, 74, 255, 0.6);
          box-shadow: 0 3px 0 0 rgba(110, 74, 255, 0.6);
}

.subnav > li:not(.active):hover > a {
  color: #696969;
}

.subnav > li:not(.active):hover:before {
  background-color: rgba(0, 0, 0, 0.2);
}

.subnav li > a {
  color: #726969;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  display: block;
}

.subnav li.active {
  color: #585858;
  background: none;
  cursor: initial;
}

.subnav li.active > a, .subnav li.active > a:focus {
  border: 0;
  background: none;
  color: #585858;
}

.subnav li:before {
  height: 4px;
  -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
          transform: translateY(100%);
  opacity: .6;
}

.subnav.small li:before {
  height: 3px;
}

@media only screen and (max-width: 767px) {
  .subnav{
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .subnav.large li{
    max-width: 50%;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    margin-bottom: 10px;
  }
}
