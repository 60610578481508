.floaraTextCobalt .fr-toolbar .fr-btn-grp {
    margin: 0 17px 0 12px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-align: center;
        align-items: center;
    padding: 4px 0;
}

.floaraTextCobalt .fr-toolbar .fr-command.fr-btn.fr-dropdown,
.floaraTextCobalt .fr-toolbar .fr-command.fr-btn{
	background: var(--color-gray-25);
	padding: 0;
	height: 28px;
    margin: 5px 1px 5px 0;
}

.floaraTextCobalt .fr-toolbar .fr-command.fr-btn{
	background: transparent;
    min-width: 34px;
    border: none !important;
}

.floaraTextCobalt .fr-toolbar .fr-command.fr-btn.fr-dropdown{
    min-width: 130px;
}

.floaraTextCobalt .fr-toolbar .fr-command.fr-btn.fr-dropdown:after{
	right: 7px;
    top: 12px;
}

.floaraTextCobalt .fr-toolbar .fr-btn-wrap .fr-command.fr-btn.fr-dropdown.fr-options {
    height: 28px;
    top: 0;
    background: transparent;
    min-width: 10px;
    left: -4px;
    border: 1px solid transparent !important;
}

.floaraTextCobalt .fr-toolbar .fr-btn-wrap .fr-command.fr-btn.fr-dropdown.fr-options:after {
    right: 2px;
}

.floaraTextCobalt .fr-toolbar .fr-command.fr-btn svg{
	margin: 0 auto;
}

.floaraTextCobalt .fr-toolbar .fr-btn-wrap .fr-command.fr-btn.fr-dropdown.fr-options.fr-btn-hover,
.floaraTextCobalt .fr-toolbar .fr-command.fr-btn.fr-btn-hover{
    background: transparent;
    border: 1px solid var(--color-gray-26) !important;
}

.floaraTextCobalt .fr-toolbar .fr-command.fr-btn.fr-dropdown:hover,
.floaraTextCobalt .fr-toolbar .fr-command.fr-btn:hover,
.floaraTextCobalt .fr-toolbar .fr-btn-wrap .fr-command.fr-btn.fr-dropdown.fr-options:hover,
.floaraTextCobalt .fr-toolbar .fr-btn-wrap .fr-command.fr-btn.fr-dropdown.fr-options.fr-active,
.floaraTextCobalt .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
.floaraTextCobalt .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover,
.floaraTextCobalt .fr-toolbar .fr-command.fr-btn.fr-expanded,
.floaraTextCobalt .fr-toolbar .fr-command.fr-btn.fr-btn-active-popup,
.floaraTextCobalt .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a:hover,
.floaraTextCobalt .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active{
	background: var(--color-gray-26);
}

.floaraTextCobalt .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list {
    padding: 4px;
    border: 1px solid transparent;
    border-radius: 6px;
    -webkit-box-shadow: 0 0 2px 0 rgba(34, 47, 62, .2), 0 4px 8px 0 rgba(34, 47, 62, .15);
            box-shadow: 0 0 2px 0 rgba(34, 47, 62, .2), 0 4px 8px 0 rgba(34, 47, 62, .15);
    display: inline-block;
    min-width: 130px;
}

.floaraTextCobalt .fr-command.fr-btn.fr-active+.fr-dropdown-menu {
    margin-top: 5px;
}

.floaraTextCobalt .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: 'Nunito', sans-serif !important;
}

.floaraTextCobalt .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a{
	line-height: inherit;
	padding: 4px 8px;
    font-weight: inherit;
}

.floaraTextCobalt .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active{
	padding-right: 36px;
	position: relative;
}

.floaraTextCobalt .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active:after {
    content: '';
    position: absolute;
    right: 8px;
    width: 13px;
    height: 7px;
    border-left: 2px solid var(--color-grayish-blue);
    border-bottom: 2px solid var(--color-grayish-blue);
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 50%;
    margin-top: -7px;
}

.floaraTextCobalt .fr-toolbar .fr-command.fr-btn.fr-dropdown svg {
    margin: 2px 1px 0px 4px;
}

.floaraTextCobalt .fr-toolbar .fr-command.fr-btn.fr-dropdown[data-cmd='fontSize']>span {
    width: auto !important;
}

.floaraTextCobalt .fr-popup .fr-color-set .fr-selected-color {
    font-size: 0;
}

.floaraTextCobalt .fr-popup .fr-color-set .fr-selected-color:before {
    content: '';
    position: absolute;
    right: 10px;
    width: 13px;
    height: 7px;
    border-left: 3px solid var(--color-white);
    border-bottom: 3px solid var(--color-white);
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 50%;
    margin-top: -6px;
    display: block;
}