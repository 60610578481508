:import("./colors.css") {
  i__const_c_black_3: c_black;
  i__const_c_red_4: c_red;
  i__const_c_purple_light_5: c_purple_light;
  i__const_c_grey_mid_6: c_grey_mid;
}

:export {
  c_black: i__const_c_black_3;
  c_red: i__const_c_red_4;
  c_purple_light: i__const_c_purple_light_5;
  c_grey_mid: i__const_c_grey_mid_6;
}

button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.btn {
  display: inline-block;
  padding: 0.7rem 1.2rem;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f5f5f5));
  background: -o-linear-gradient(#fff, #f5f5f5);
  background: linear-gradient(#fff, #f5f5f5);
  /* color: #FF615C; */
  color: #474747;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2px rgba(10, 0, 50, 0.2);
          box-shadow: 0 1px 2px rgba(10, 0, 50, 0.2);
  font-weight: 600;
  letter-spacing: 0;
  border: 1px solid #ccc;
}

/*Psuedo classes*/

.btn:hover {
  -webkit-box-shadow: 0 2px 3px rgba(40, 20, 70, 0.3);
          box-shadow: 0 2px 3px rgba(40, 20, 70, 0.3);
}

.btn:hover, .btn:focus {
  border: 1px solid #777;
  background: #fff;
}

.btn:active, .btn:active:focus:active, .btn:focus:active {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.fullSizeBtn {
  width: 100%;
  border: 0;
  background: none;
  background-color: #666;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.fullSizeBtn:hover, .fullSizeBtn:focus {
  border: 0;
  background: #7d7d7d;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
}

.tag {
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  color: #5e5e5e;
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  margin-left: 0.6rem;
  -ms-flex-align: center;
      align-items: center;
  overflow: hidden;
  font-weight: 400;
  font-size: 1rem;
  margin-right: 0.5rem;
}

.tag:hover {
  background: rgba(0,0,0,0.1);
}

.tag .tagText {
  margin-left: 0.7rem;
  text-transform: capitalize;
}
