/* based on https://www.figma.com/file/nY4U4FdSgXAtk5m8LOhqpI/Usage-Analytics-Master?node-id=105%3A2288 */
:root {

  --color-white: #FFFFFF;
  --color-black: #000000;

  --color-cobalt: #2741AE;
  --color-cobalt-2: #7B91F1;
  --color-cobalt-3: #D3E2FF;

  --color-purple: #561AD6;
  --color-warning-orange: #EC6C23;
  --color-success-green: #097D55;
  --color-success-green-light: #097D55;
  --color-error-red: #CB000E;
  --color-error-red-light: #CB000E;

  --color-gray-1: #5D6973;
  --color-gray-2: #7C8893;
  --color-gray-3: #9BAAB6;
  --color-gray-4: #BECBD5;
  --color-gray-5: #D7E1EB;
  --color-gray-6: #EAF1F7;
  --color-gray-7: #F6F9FC;
  --color-gray-8: #B9C8FF;
  --color-gray-9: #565658CC;
  --color-gray-10: #00000080;
  --color-gray-11: #00000012;
  --color-gray-12: #595959;
  --color-gray-13: #111;
  --color-gray-14: #2F2F2F;
  --color-gray-15: #D9D9D9;
  --color-gray-16: #F5F5F5;
  --color-gray-17: #656565;
  --color-gray-18: #EEEDED;
  --color-gray-19: #F2F6FA;
  --color-gray-20: #424242;
  --color-gray-21: #DCE1F2;
  --color-gray-22: #58636D;
  --color-gray-23: #5A5A5A;
  --color-gray-24: #9E9E9E;
  --color-gray-25: #F7F7F7;
  --color-gray-26: #CCE2FA;
  --color-gray-27: #333333;
  
  --color-light-gray-1: #707070;

  --color-light-blue-1: #6E4AFF;
  --color-light-blue-2: #4A6173;
  --color-light-blue-3: #2949BC;
  --color-light-orange-1: #D35B38;
  --color-light-orange-2: #E00700;
  --color-light-white-1: #dddddd;
  

  --color-midnight: #11234F;
  --color-indigo: #172C62;
  --color-dusk: #607BBF;
  --color-slate: #77A3D2;
  --color-mursion-red: #FF615C;
  --color-yellow: #F8C539;
  --color-sea-green: #5EC9C6;
  --color-sky-blue: #4BB8F5;
  --color-violet: #A949A5;
  --color-blue: #1F67F4;
  --color-moonlight: #fff7f7;
  --color-moonlight-2:#F4F6FB;
  --color-vivid-orange: #F17D11;
  --color-base-blue:#dcf3ff;
  --color-light-blue:#439bcb;
  --color-medium-blue:#276c91;
  --color-dark-blue:#063e5c;

  /* not listed in theme config */
  --color-other-snuff: #E0E4F2;
  --color-info: #BBC3E2;
  --color-erro-light: #FFBCBA;
  --color-success-light: #94E8E5;
  --color-light-green: #34A4A0;
  --color-light-orange: #FF7E74;
  --color-orange-yellow: #F9A557;
  --color-light-yellow : #F9CF57;
  --color-floral-white : #FFF8EE;
  --color-cornflower-blue : #5F7AEA;
  --color-gainsboro: #D9D9D9;
  --color-light-grayish-blue : #dee2e6;
  --color-light-grayish-red: #bbb;
  --color-light-white: #f6f6f6;
  --color-light-blue: #337ab7;
  --color-light-gray: #575757;
  --color-dark-gray: #505050;
  --color-dark-gray-2: #474747;
  --color-base-light-gray: #BBBBBB;
  --color-base-gray: #CCCCCC;
  --color-base-green: #088806;
  --color-legent-hover: #EEF3FF;
  --color-base-light-blue: #E9F4FF;
  --color-base-light-green: #C5FCFA;
  --color-base-light-success: #3AB6B2;
  --color-pale-purple: #F1E2FC;
  --color-dark-grayish-red: #3F3B3C;
  --color-strong-pink: #A32EAD;
  --color-light-orange-2: #FFD670;
  --color-light-base-gray: #565456;
  --color-light-base-gray-2: #E2E2E2;
  --color-light-base-gray-3: #FFFEFF;
  --color-light-base-gray-4: #E0DFE0;
  --color-light-base-gray-5: #D1D0D1;
  --color-dark-base-gray: #6F6D6F;
  --color-dark-base-gray-2: #757575;
  --color-dark-base-gray-3: #767577;
  --color-dark-base-gray-4: #666666;
  --color-bright-red: #D55A30;
  --color-bright-success: #06817D;
  --color-pale-blue: #B9C4D0;
  --color-pale-blue-2: #F3F7FF;
  --color-pale-gray: #636363;
  --color-light-pale-gray: #637588;
  --color-light-pale-blue: #ADBDCA;
  --color-cobalt-disabled: #e9e9e9;
  --color-dark-grayish-orange: #241C15;
  --color-light-grayish-orange: #FFFCFB;
  --color-burnt-orange: #C04C0D;
  --color-ochre: #BF9000;
  --color-dark-lavender: #674EA7;
  --color-Mercury-gray: #E5E5E5;
  --color-soft-navy-blue: #6673AF;
  --color-dim-gray: #696969;
  --color-vivid-navy: #2563EB;
  --color-violet-2: #BA78E2;
  --color-grayish-blue: #222F3E;
  --color-dark-grayish-navy: #1D2433;
}

:export{
  white : var(--color-white);
  cobalt3 : var(--color-cobalt-3);
  lightGreen : var(--color-light-green);
  lightOrange : var(--color-light-orange);
  orangeYellow : var(--color-orange-yellow);
  lightYellow : var(--color-light-yellow);
  sGrayFive : var(--color-gray-5);
  sBlackOne : var(--color-black);
  baseBlue: var(--color-base-blue);
  lightBlue: var(--color-light-blue);
  mediumBlue: var(--color-medium-blue);
  darkBlue: var(--color-dark-blue);
  violet: var(--color-violet-2);
  cornflowerBlue : var(--color-cornflower-blue);
  successLight: var(--color-success-light);
}
